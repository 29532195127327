import React from "react";
import sanitizeHTML, { IOptions } from "sanitize-html";

interface SanitizedHTMLProps extends IOptions {
  html: string;

  className?: string;
  id?: string;
  style?: any;
}

const SUPPORTED_SANITIZER_OPTIONS: (keyof IOptions)[] = [
  "allowProtocolRelative",
  "allowedAttributes",
  "allowedClasses",
  "allowedSchemes",
  "allowedSchemesByTag",
  "allowedTags",
  "exclusiveFilter",
  "nonTextTags",
  "parser",
  "selfClosing",
  "transformTags",
];

const SanitizedHTML = (props: SanitizedHTMLProps) => {
  const sanitizerOptions = SUPPORTED_SANITIZER_OPTIONS.reduce(
    (options, name) => {
      const value: any = props[name];

      if (typeof value !== "undefined") {
        options[name] = value;
      }

      return options;
    },
    {} as IOptions
  );

  // TODO: Add memoization
  const sanitizedHTML = sanitizeHTML(props.html, sanitizerOptions);

  return (
    <div
      className={props.className}
      dangerouslySetInnerHTML={{ __html: sanitizedHTML }}
      id={props.id}
      style={props.style}
    />
  );
};

export default SanitizedHTML;
