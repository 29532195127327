import React from "react";
import { Container, Card, Row, Col } from "react-bootstrap";
import { Helmet } from "react-helmet-async";

import FeedbackForm from "../components/Feedback";

const Feedback = () => {
  return (
    <React.Fragment>
      <Helmet title="Feedback" />
      <Container fluid className="p-0">
        <Row>
          <Col md={8} xl={6} className="mx-auto py-5">
            <Card>
              <Card.Header>
                <h1 className="h4">Feedback</h1>
              </Card.Header>
              <Card.Body>
                <FeedbackForm />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default Feedback;
