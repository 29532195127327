import React from "react";

import { useConversation } from "../data/useConversation";
import { Conversation, ConversationListElement } from "../types";

type ConversationContextType = {
  conversation?: Conversation;
  loading: boolean;
  updating: boolean;
  updateConversation: (
    conversation: ConversationListElement,
    message: string
  ) => void;
  selectedConversationID: ConversationListElement | null;
  setSelectedConversationID: (
    selectedConversation: ConversationListElement
  ) => void;
  newConversation: (shopID: number) => void;
  conversations: Conversation[];
};

const initialState: ConversationContextType = {
  conversation: undefined,
  loading: false,
  updating: false,
  updateConversation: (
    conversation: ConversationListElement,
    message: string
  ) => {},
  selectedConversationID: null,
  setSelectedConversationID: (
    selectedConversation: ConversationListElement
  ) => {},
  newConversation: (shopID: number) => {},
  conversations: [],
};

const ConversationContext = React.createContext(initialState);

interface Props {
  children?: React.ReactElement | React.ReactElement[] | null | undefined;
}

const ConversationProvider = ({ children }: Props) => {
  const {
    conversation,
    loading,
    updating,
    updateConversation,
    selectedConversationID,
    setSelectedConversationID,
    newConversation,
    conversations,
  } = useConversation();

  return (
    <ConversationContext.Provider
      value={{
        conversation,
        loading,
        updating,
        updateConversation,
        selectedConversationID,
        setSelectedConversationID,
        newConversation,
        conversations,
      }}
    >
      {children}
    </ConversationContext.Provider>
  );
};

const useConversationContext = () => React.useContext(ConversationContext);

export { ConversationProvider, useConversationContext };
