import React from "react";
import { ListGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShoppingBag } from "@fortawesome/free-solid-svg-icons";
import { logEvent } from "firebase/analytics";

import { useShops } from "../data/useShops";
import { useConversationContext } from "../contexts/ConversationContext";
import { ShowChatProps } from "../types";
import { firebaseAnalytics } from "..";

const ShopifyStoreList = ({ showChatList, setShowChatList }: ShowChatProps) => {
  const { shops, loading } = useShops();
  const { newConversation, selectedConversationID } = useConversationContext();

  if (loading) {
    return <div>Loading...</div>;
  }

  // when a user clicks on a store, create a new conversation
  const handleClick = (shopID: number) => {
    newConversation(shopID);
    logEvent(firebaseAnalytics, "select_content", {
      items: [{ content_id: shopID.toString(), content_type: "shop" }],
    });
    setShowChatList(false);
  };

  return (
    <ListGroup>
      {shops.map((shop) => (
        <ListGroup.Item
          onClick={() => handleClick(shop.id)}
          action
          active={selectedConversationID?.shop_id === shop.id}
          className="border-0"
          key={`shop-${shop.id}`}
        >
          <div className="d-flex">
            {shop.icon_url ? (
              <img src={shop.icon_url} alt={shop.name} width="40" />
            ) : (
              <FontAwesomeIcon icon={faShoppingBag} size={"3x"} />
            )}
            <div className="flex-grow-1 ms-3">
              {shop.name}
              <div className="small">{shop.domain}</div>
            </div>
          </div>
        </ListGroup.Item>
      ))}
    </ListGroup>
  );
};

export default ShopifyStoreList;
