import React, { useState, useEffect } from "react";

import {
  Button,
  InputGroup,
  Form,
  ListGroup,
  Image,
  Row,
  Col,
  Container,
  Toast,
} from "react-bootstrap";
import ScrollToBotton from "react-scroll-to-bottom";

import avatar1 from "../assets/img/avatars/avatar1.png";
import avatar2 from "../assets/img/avatars/avatar2.png";

import { useConversationContext } from "../contexts/ConversationContext";
import SanitizedHTML from "./SanitizedHTML";
import { Conversation, ShowChatProps } from "../types";

interface ChatBubbleType {
  position: string;
  avatar: string;
  name: string;
  children: React.ReactNode;
  time: string;
}

const ChatBubble = ({
  position,
  avatar,
  name,
  children,
  time,
}: ChatBubbleType) => (
  <div className={`chat-message-${position} pb-4`}>
    <div>
      <img
        src={avatar}
        className="rounded-circle mr-1"
        alt={name}
        width="40"
        height="40"
      />
      <div className="text-muted small text-nowrap mt-2">{time}</div>
    </div>
    <div
      className={`flex-shrink-1 bg-light rounded py-2 px-3 ${
        position === "left" ? "mr-3" : "ml-3"
      }`}
    >
      <div className="font-weight-bold mb-1">{name}</div>
      {children}
    </div>
  </div>
);

const createChatBubbleList = ({
  conversation,
}: {
  conversation?: Conversation;
}) => {
  const chatBubbles: JSX.Element[] = [];
  if (!conversation) {
    return chatBubbles;
  }
  let count_in = 0;
  let count_out = 0;
  for (
    let index = 0;
    index <
    conversation.incoming_messages.length +
      conversation.outgoing_messages.length;
    index++
  ) {
    if (index % 2) {
      chatBubbles.push(
        <ChatBubble
          position="right"
          avatar={avatar1}
          name="You"
          time={new Date(
            conversation.incoming_messages[count_in].modified
          ).toLocaleTimeString()}
          key={`chat-bubble-${index}`}
        >
          {conversation.incoming_messages[count_in].message}
        </ChatBubble>
      );
      count_in++;
    } else {
      chatBubbles.push(
        <ChatBubble
          position="left"
          avatar={avatar2}
          name="ShopGPT"
          time={new Date(
            conversation.outgoing_messages[count_out].modified
          ).toLocaleTimeString()}
          key={`chat-bubble-${index}`}
        >
          {
            <SanitizedHTML
              html={conversation.outgoing_messages[count_out].message}
              allowedTags={["b", "i", "em", "strong", "a"]}
            />
          }
          {conversation.outgoing_messages[count_out].recommended_products
            ?.length ? (
            <div>
              <small
                id={`show_recommendations_${index}`}
                hidden
                onClick={(event) => {
                  (event.target as HTMLElement).hidden = true;
                  document
                    .getElementById(`recommendations_${index}`)
                    ?.classList.add("show");
                }}
                className="text-info"
              >
                Show recommendations...
              </small>
              <Toast
                id={`recommendations_${index}`}
                onClick={(event) => {
                  (
                    event.target as HTMLElement
                  ).parentElement?.parentElement?.classList.remove("show");
                  document.getElementById(
                    `show_recommendations_${index}`
                  )!.hidden = false;
                }}
              >
                <Toast.Header>
                  <strong className="me-auto">Recommended Products</strong>
                  <small>
                    {
                      conversation.outgoing_messages[count_out]
                        .recommended_products?.length
                    }
                    {" items"}
                  </small>
                </Toast.Header>
                <Toast.Body>
                  <ListGroup>
                    {conversation.outgoing_messages[
                      count_out
                    ].recommended_products?.map((product, idx) => (
                      <ListGroup.Item
                        action
                        target={"_blank"}
                        key={`${index}_${idx}_${product.handle}`}
                        href={product.url}
                      >
                        <Image src={product.image_url} height={40} rounded />
                        <span className="ps-2">{product.title}</span>
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                </Toast.Body>
              </Toast>
            </div>
          ) : null}
        </ChatBubble>
      );
      count_out++;
    }
  }
  return chatBubbles;
};

function delay(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

const ChatConversation = ({ setShowChatList }: ShowChatProps) => {
  const {
    conversation,
    loading,
    updateConversation,
    updating,
    selectedConversationID,
  } = useConversationContext();
  const [chatBubbles, setChatBubbles] = useState(
    createChatBubbleList({ conversation })
  );

  useEffect(() => {
    setChatBubbles(createChatBubbleList({ conversation }));
  }, [conversation]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!selectedConversationID || !conversation) {
    return <div>Select a store to begin chatting...</div>;
  }

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    setShowChatList(false);
    event.preventDefault();
    const form = event.currentTarget;
    const formData = new FormData(form);
    const message = formData.get("user_message") as string;
    delay(200)
      .then(() => {
        setChatBubbles([
          ...chatBubbles,
          <ChatBubble
            position="right"
            avatar={avatar1}
            name="You"
            time="sending..."
            key={`chat-bubble-pending`}
          >
            {message}
          </ChatBubble>,
        ]);
      })
      .then(() => delay(1000))
      .then(() => {
        setChatBubbles([
          ...chatBubbles,
          <ChatBubble
            position="right"
            avatar={avatar1}
            name="You"
            time="sending..."
            key={`chat-bubble-pending`}
          >
            {message}
          </ChatBubble>,
          <ChatBubble
            position="left"
            avatar={avatar2}
            name="ShopGPT"
            time="typing..."
            key={`chat-bubble-thinking`}
          >
            <div
              className="spinner-border spinner-border-sm text-info m-2"
              role="status"
            >
              <span className="visually-hidden">Loading...</span>
            </div>
            <div
              className="spinner-border spinner-border-sm text-info m-2"
              role="status"
            >
              <span className="visually-hidden">Loading...</span>
            </div>
            <div
              className="spinner-border spinner-border-sm text-info m-2"
              role="status"
            >
              <span className="visually-hidden">Loading...</span>
            </div>
          </ChatBubble>,
        ]);
      });
    updateConversation(selectedConversationID, message);
    form.reset();
  };

  return (
    <React.Fragment>
      <Container>
        <Row>
          <div
            className="py-2 px-4 border-bottom d-sm-block"
            onClick={(e) => setShowChatList(true)}
          >
            <div className="d-flex align-items-center py-1">
              <div className="position-relative">
                <img
                  src={avatar2}
                  className="rounded-circle me-1"
                  alt="ShopGPT"
                  width="40"
                  height="40"
                />
              </div>
              <div className="flex-grow-1 ms-3 ps-3">
                <strong>{conversation.shop.name}</strong>
                <div className="text-muted small">
                  <em>{conversation.shop.domain}</em>
                </div>
              </div>
            </div>
          </div>
        </Row>
        <Row>
          <ScrollToBotton className="chat-messages p-4">
            {chatBubbles}
          </ScrollToBotton>
        </Row>
        <Row className="p-4">
          <Col>
            <Form onSubmit={onSubmit}>
              <InputGroup>
                <Form.Control
                  name="user_message"
                  type="text"
                  placeholder="Type your message"
                  autoComplete="off"
                  required
                />
                <Button
                  id="update_conversation_button"
                  variant="primary"
                  disabled={updating}
                  type="submit"
                >
                  Send
                </Button>
              </InputGroup>
            </Form>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default ChatConversation;
