import React, { useState } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import { Helmet } from "react-helmet-async";

import { ConversationProvider } from "../contexts/ConversationContext";
import ChatConversation from "../components/ChatConversation";
import ShopifyStoreList from "../components/ShopifyStoreList";
import "../assets/css/chat.css";

const searchEnabled = false;

const ShopGPT = () => {
  const [showChatList, setShowChatList] = useState(true);

  return (
    <React.Fragment>
      <ConversationProvider>
        <Helmet title="ShopGPT Chat" />
        <Container fluid className="p-0">
          <Row className="g-0">
            <Col
              xs={showChatList ? 12 : 0}
              xl={3}
              md={4}
              className={
                "mx-auto chat-list d-md-block" +
                (showChatList ? " d-block" : " d-none")
              }
            >
              <div className="px-4">
                <div className="d-flex align-items-center">
                  <div className="flex-grow-1 ms-3">
                    {searchEnabled ? (
                      <Form.Control
                        type="text"
                        className="my-3"
                        placeholder="Search..."
                      />
                    ) : (
                      <h6 className="mb-0">Choose a shop</h6>
                    )}
                  </div>
                </div>
              </div>
              <ShopifyStoreList
                showChatList={showChatList}
                setShowChatList={setShowChatList}
              />

              <hr className="d-block d-lg-none mt-1 mb-0" />
            </Col>
            <Col xs={12} md={8} className="mx-auto">
              <ChatConversation
                showChatList={showChatList}
                setShowChatList={setShowChatList}
              />
            </Col>
          </Row>
        </Container>
      </ConversationProvider>
    </React.Fragment>
  );
};

export default ShopGPT;
