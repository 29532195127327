import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import axios from "axios";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane, faHeart } from "@fortawesome/free-solid-svg-icons";

import { Feedback as FeedbackType } from "../types";
import { API_URL } from "../constants";

// Feedback form component
// with three fields: category, message, and email
const Feedback = () => {
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const form = event.currentTarget;
    const formData = new FormData(form);

    if (form.checkValidity() === false) {
      event.stopPropagation();
    }
    setSubmitted(true);
    const feedback: FeedbackType = {
      category: formData.get("feedbackCategory") as string,
      message: formData.get("feedbackMessage") as string,
      email: formData.get("feedbackEmail") as string,
    };
    axios.post(`${API_URL}feedback/new_feedback/`, feedback);
  };

  return (
    <React.Fragment>
      {submitted ? (
        <div className="text-center h3 p-5">
          <FontAwesomeIcon icon={faHeart} className="me-2" /> Thank you for your
          feedback!
        </div>
      ) : (
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="feedbackCategory" className="py-2">
            <Form.Label>Category</Form.Label>
            <Form.Control as="select" name="feedbackCategory">
              <option>General</option>
              <option>Store</option>
              <option>Chat</option>
              <option>Other</option>
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="feedbackMessage" className="py-2">
            <Form.Label>Message</Form.Label>
            <Form.Control
              as="textarea"
              rows={4}
              required
              name="feedbackMessage"
            />
          </Form.Group>
          <Form.Group controlId="feedbackEmail" className="py-2">
            <Form.Label>Your Email Address</Form.Label>
            <Form.Control
              type="email"
              name="feedbackEmail"
              placeholder="Please enter your email"
              required
            />
            <Form.Text className="text-muted">
              I'll never share your email with anyone else, but I may contact
              you about your comment.
            </Form.Text>
          </Form.Group>
          <div className="text-center">
            <Button variant="primary" type="submit">
              <FontAwesomeIcon icon={faPaperPlane} className="me-2" /> Submit
            </Button>
          </div>
        </Form>
      )}
    </React.Fragment>
  );
};

export default Feedback;
