export const API_URL = "https://api.shopgpt24.com/";
// export const API_URL = "http://localhost:8000/";
export const firebaseConfig = {
  apiKey: "AIzaSyDDDb7QxlJHxDzVzdw9qgh59fRICJuoM70",
  authDomain: "shopgpt-24.firebaseapp.com",
  projectId: "shopgpt-24",
  storageBucket: "shopgpt-24.appspot.com",
  messagingSenderId: "999440231198",
  appId: "1:999440231198:web:331c3242bfaaa06c9af70f",
  measurementId: "G-SXSDJ0YLLJ",
};
