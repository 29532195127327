import React from "react";
import { Container, Card, Row, Col } from "react-bootstrap";
import { Helmet } from "react-helmet-async";

const About = () => {
  return (
    <React.Fragment>
      <Helmet title="About" />
      <Container fluid className="p-0">
        <Row>
          <Col md={8} xl={6} className="mx-auto py-5">
            <Card>
              <Card.Header>
                <h1 className="h4">About ShopGPT-24</h1>
              </Card.Header>
              <Card.Body>
                <p>
                  ShopGPT-24 is a chatbot that helps you find the best products.
                  It uses the power of GPT-3 / GPT-3.5 and soon GPT-4 to help
                  you browse the contents of a shop. It uses training data from
                  each shop to learn about the products and then responds to
                  your questions in a natural way. You can have a conversation
                  with the bot and it will try to act the part of a helpful shop
                  assistant.
                </p>
                <p>
                  To use ShopGPT-24, just select a store from the list, and
                  start talking to the chatbot. You can ask it questions like:
                </p>
                <ul>
                  <li>What are your best selling products?</li>
                  <li>Describe the products you sell</li>
                  <li>
                    I'm looking for a gift for my [friend/lover/child], what
                    would you suggest?
                  </li>
                  <li>
                    Explain the rules of pickleball to me. And then tell me what
                    kind of paddle to buy.
                  </li>
                </ul>
                <p>
                  If you don't see a store listed that you want to talk to,
                  please let me know in the <a href="/feedback">Comments</a>.
                </p>
                <h1 className="h5">Version History:</h1>
                <p>
                  <ol>
                    <li>
                      <b>3/30/23:</b> Initial Release: Works only with Shopify
                      shops that have publicly listed product descriptions. The
                      prompts given to GPT-3 are the product descriptions and
                      titles. The products that are chosen to be part of the
                      prompt are ranked based on how closely they match the
                      user's query. The user's query is examined for semantic
                      meaning by an embeddings engine. It seems to work well for
                      most queries. Future versions will allow for more keyword
                      analysis of the user query and will allow for more complex
                      prompt design.
                    </li>
                  </ol>
                </p>
              </Card.Body>
              <Card.Footer>
                <p>
                  ShopGPT-24 is a project by{" "}
                  <a href="https://www.linkedin.com/in/gravengaard">
                    Eric Gravengaard
                  </a>
                </p>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default About;
