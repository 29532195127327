import React from "react";
import { Container, Card, Row, Col } from "react-bootstrap";
import { Helmet } from "react-helmet-async";

const StoreOwner = () => {
  return (
    <React.Fragment>
      <Helmet title="Info for Shop Owners" />
      <Container fluid className="p-0">
        <Row>
          <Col md={8} xl={6} className="mx-auto py-5">
            <Card>
              <Card.Header>
                <h1 className="h4">Info for Shop Owners</h1>
              </Card.Header>
              <Card.Body>
                <p>
                  ShopGPT-24 is a chatbot that helps customers find the best
                  products. It uses the power of GPT-3 / GPT-3.5 and soon GPT-4
                  to help users browse the contents of a shop. It uses training
                  data from each shop to learn about the products and then
                  responds to their questions in a natural way. You can have a
                  conversation with the bot and it will try to act the part of a
                  helpful shop assistant.
                </p>
                <p>
                  If you would like your shop removed from this site please let
                  me know. Or if you would like to add your shop to this site,
                  please let me know that too. I will be happy to add your shop
                  to the list. I can also work on custom integrations for your
                  shop. These can include custom training data, custom
                  responses, and custom integrations with your shop's API. You
                  could configure sales, specials, or have the chatbot promote
                  certain products. I'm also open to other ideas. Please let me
                  know if you are interested.
                </p>
                <p>
                  You can contact me through the{" "}
                  <a href="/feedback">Comments Page</a> or through my LinkedIn
                  Page here:{" "}
                  <a href="https://www.linkedin.com/in/gravengaard">
                    Eric Gravengaard
                  </a>
                </p>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default StoreOwner;
