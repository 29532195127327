import { useState, useEffect } from "react";
import axios from "axios";

import { API_URL } from "../constants";
import { ShopifyShop } from "../types";

export const useShops = () => {
  const [shops, setShops] = useState<ShopifyShop[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchShops = async () => {
      const response = await axios.get<ShopifyShop[]>(
        `${API_URL}shopify_data/shopify_shops/`
      );
      setShops(response.data);
      setLoading(false);
    };
    fetchShops();
  }, []);

  return { shops, loading };
};
