import React from "react";

// Layouts
import LandingLayout from "./layouts/Landing";
import MainLayout from "./layouts/MainSite";

// Landing

// Pages
import SelectStore from "./pages/SelectStore";
import ShopGPT from "./pages/ShopGPT";
import About from "./pages/About";
import Feedback from "./pages/Feedback";
import Owners from "./pages/StoreOwner";

const routes = [
  {
    path: "/",
    element: <MainLayout />,
    children: [
      { path: "old", element: <SelectStore /> },
      { path: "", element: <ShopGPT /> },
      { path: "/about", element: <About /> },
      { path: "/feedback", element: <Feedback /> },
      { path: "/owners", element: <Owners /> },
    ],
  },
];

export default routes;
