import React from "react";
import { Outlet } from "react-router-dom";

import Main from "../components/Main";
import Navbar from "../components/Navbar";

interface Props {
  children?: React.ReactElement | React.ReactElement[] | null | undefined;
}

const MainSite: React.FC<Props> = ({ children }) => (
  <React.Fragment>
    <Main>
      <Navbar />
      {children}
      <Outlet />
    </Main>
  </React.Fragment>
);

export default MainSite;
