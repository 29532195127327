import React from "react";
import { useRoutes } from "react-router-dom";
import { HelmetProvider, Helmet } from "react-helmet-async";

import routes from "./routes";

const App = () => {
  const content = useRoutes(routes);

  return (
    <HelmetProvider>
      <Helmet
        titleTemplate="%s | ShopGPT24.com"
        defaultTitle="Shop and Chat All Day | ShopGPT24.com"
      />
      {content}
    </HelmetProvider>
  );
};

export default App;
