import React from "react";

import { Navbar, Nav } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShop, faRobot, faXmark } from "@fortawesome/free-solid-svg-icons";

const NavbarComponent = () => (
  <Navbar bg="light" expand="sm">
    <Navbar.Brand href="/" className="ps-3">
      <FontAwesomeIcon icon={faShop} className="me-2" />
      <FontAwesomeIcon icon={faXmark} className="me-2" />
      <FontAwesomeIcon icon={faRobot} className="me-2" /> ShopGPT-24
    </Navbar.Brand>
    <Navbar.Toggle aria-controls="basic-navbar-nav" />
    <Navbar.Collapse>
      <Nav className="me-auto">
        <Nav.Link href="/about">About</Nav.Link>
        <Nav.Link href="/feedback">Feedback</Nav.Link>
        <Nav.Link href="/owners">Shop Owners</Nav.Link>
      </Nav>
    </Navbar.Collapse>
  </Navbar>
);

export default NavbarComponent;
