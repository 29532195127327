import { useState, useEffect } from "react";
import axios from "axios";

import { API_URL } from "../constants";
import { Conversation, ConversationListElement } from "../types";

// load the conversation from local storage
// if it exists, load it
// if it doesn't exist, return an empty list
const loadConversations = (): Conversation[] => {
  const conversations = localStorage.getItem("shop-gpt-conversations");
  if (conversations) {
    const parsedConversations = JSON.parse(conversations);
    return parsedConversations.map((conversation: ConversationListElement) => ({
      id: conversation.conversation_id,
      secret: conversation.conversation_secret,
      shop: {
        id: conversation.shop_id,
      },
    }));
  } else {
    return [];
  }
};

const loadSelectedConversation = (): ConversationListElement | null => {
  const selectedConversation = localStorage.getItem("selectedConversationID");
  if (selectedConversation) {
    return JSON.parse(selectedConversation);
  } else {
    return null;
  }
};

export const useConversation = () => {
  const [selectedConversationID, setSelectedConversationID] =
    useState<ConversationListElement | null>(loadSelectedConversation());
  const [conversations, setConversations] = useState<Conversation[]>(
    loadConversations()
  );
  const [conversation, setConversation] = useState<Conversation>();
  const [loading, setLoading] = useState(true);
  const [updating, setUpdating] = useState(false);

  useEffect(() => {
    const fetchConversation = async () => {
      setLoading(true);
      const response = await axios.get<Conversation>(
        `${API_URL}assistant/conversations/${selectedConversationID?.conversation_id}/`
      );
      setConversation(response.data);
      setLoading(false);
    };

    if (!selectedConversationID) {
      setLoading(false);
      return;
    }

    fetchConversation().then(() => {
      localStorage.setItem(
        "selectedConversationID",
        JSON.stringify(selectedConversationID)
      );
    });
  }, [selectedConversationID]);

  const newConversation = async (shopID: number) => {
    // search for a conversation with this shop ID
    const existingConversation = conversations.find(
      (c) => c.shop.id === shopID
    );
    if (existingConversation) {
      setSelectedConversationID({
        shop_id: existingConversation.shop.id,
        conversation_id: existingConversation.id,
        conversation_secret: existingConversation.secret || "",
      });
      return;
    }

    // if it doesn't exist, create a new conversation
    const response = await axios.post<Conversation>(
      `${API_URL}shopify_data/shopify_shops/${shopID}/new_conversation/`
    );
    setConversations([...conversations, response.data]);
    console.log("New Conversation ID: ", response.data.id);
    setSelectedConversationID({
      shop_id: response.data.shop.id,
      conversation_id: response.data.id,
      conversation_secret: response.data.secret || "",
    });
  };

  const updateConversation = async (
    conversation: ConversationListElement,
    message: string
  ) => {
    setUpdating(true);
    console.info("Sending message: ", message);
    const response = await axios.post<Conversation>(
      `${API_URL}assistant/conversations/${conversation.conversation_id}/new_message/`,
      { message: message, secret: conversation.conversation_secret }
    );
    setConversation(response.data);
    setUpdating(false);
  };

  useEffect(() => {
    // save conversations to local storage, keyed by StoreID
    // save the conversation ID and the conversation secret

    localStorage.setItem(
      "shop-gpt-conversations",
      JSON.stringify(
        conversations.map(
          (c) =>
            ({
              shop_id: c.shop.id,
              conversation_id: c.id,
              conversation_secret: c.secret || "",
            } as ConversationListElement)
        )
      )
    );
  }, [conversations]);

  return {
    conversation,
    loading,
    updating,
    updateConversation,
    selectedConversationID,
    setSelectedConversationID,
    newConversation,
    conversations,
  };
};
